import { getRouteType } from 'components/3catinfo/Enllac/RouteResolver';
import { useRouter } from 'next/router';
import { createContext, useState, useEffect } from 'react';
export const LoadingContext = createContext();

const LoadingProvider = (props) => {
    const { children } = props;

    const [activeLoading, setActiveLoading] = useState({ active: false, msg: false });
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const router = useRouter();

    const setIsActiveLoading = (active, msg = false, style = false) => {
        setActiveLoading({ active, msg, style });
    };

    const handleRouteStart = (url) => {
        url !== router.asPath && setSkeletonLoading(getRouteType(url));
    };
    const handleRouteComplete = (url) => {
        url === router.asPath &&
            setTimeout(() => {
                setSkeletonLoading(false);
            }, 500);
    };

    useEffect(() => {
        router.events.on('routeChangeStart', handleRouteStart);
        router.events.on('routeChangeComplete', handleRouteComplete);
        router.events.on('routeChangeError', handleRouteComplete);

        return () => {
            router.events.off('routeChangeStart', handleRouteStart);
            router.events.off('routeChangeComplete', handleRouteComplete);
            router.events.off('routeChangeError', handleRouteComplete);
        };
    });

    return <LoadingContext.Provider value={{ setIsActiveLoading, activeLoading, skeletonLoading }} children={children} />;
};

export default LoadingProvider;
