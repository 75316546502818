import { useRouter } from 'next/router';
import { Imatge } from '@portal-internet/components';
import { addParameterToUrl, getDataInfoVideo, getDurada, trimBR } from '@portal-internet/core';
import clsx from 'clsx';
import CodiEspectador from 'components/3catinfo/CodiEspectador';
import Icona from 'components/3catinfo/ui/Icona/Icona';
import Player from 'components/3catinfo/Player';
import __isEmpty from 'lodash/isEmpty';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styles from './itemVideoReel.module.scss';
import Compartir from 'components/3catinfo/Compartir/Compartir';

const _getSubtitols = (props) => {
    let result, subtitols;
    const text = [];

    // català
    result = props.filter((subtitol) => subtitol.id === 'CAT_CA');
    if (!__isEmpty(result)) {
        text.push('català');
    }

    // castellà
    result = props.filter((subtitol) => subtitol.id === 'CAT_ES');
    if (!__isEmpty(result)) {
        text.push('castellano (traducción automática)');
    }

    // anglès
    result = props.filter((subtitol) => subtitol.id === 'CAT_EN');
    if (!__isEmpty(result)) {
        text.push('English (automatic translation)');
    }

    // francès
    result = props.filter((subtitol) => subtitol.id === 'CAT_FR');
    if (!__isEmpty(result)) {
        text.push('Français (traduction automatique)');
    }

    if (text.length > 0) {
        subtitols = 'Subtítols en: ' + text.toString().replaceAll(',', ' / ');
        return subtitols;
    }

    return '';
};

const _getAudioDescripcio = (props) => {
    let result = false;

    if (!__isEmpty(props)) {
        result = props.filter((audiodescripcio) => audiodescripcio.id === 'AUD');
    }
    return result;
};

const getSharedLink = (id, asPath) => {
    const queryParamShare = 'shared_video';
    return addParameterToUrl(asPath, queryParamShare, id);
};

const PosterVideo = (props) => {
    const { dataVideo, onClick } = props;
    const { imatge, titol } = dataVideo;

    return (
        <div className={styles.poster} onClick={onClick}>
            <Imatge
                className={styles.imatge}
                imatges={imatge}
                tipus='web_postergran'
                alt={titol}
                layout='fill'
                objectFit='cover'
            />

            <div className={styles.icona_play}>
                <Icona icona='play' className={styles.play} alt='reproduir' isBlanc={true} mida={28} />
            </div>
        </div>
    );
};

const InfoVideo = (props) => {
    const { dataVideo } = props;
    const { titol, entradeta, entradeta_promo, id, durada } = dataVideo;
    const { asPath } = useRouter();
    const entradetaShow = trimBR(entradeta_promo) || trimBR(entradeta);

    return (
        <div className={styles.info} data-testid={`info-video-reel-${id}`}>
            {titol && <h3 className={styles.titol}>{titol}</h3>}
            {entradetaShow && (
                <div className={styles.entradeta}>
                    <p>{entradetaShow}</p>
                </div>
            )}
            <div className={styles.iconesInfoVideo}>
                {durada && (
                    <div className={styles.subinfo}>
                        <div className={styles.durada}>
                            <Icona
                                className={styles.ico_clock}
                                icona='rellotge'
                                isBlanc={true}
                                mida='13'
                                alt='Icona rellotge'
                                ariaHidden={true}
                            />
                            <div>
                                <span className='sr-only'>Durada: </span>
                                {getDurada(String(durada))}
                            </div>
                        </div>
                    </div>
                )}
                <Compartir route={{ href: getSharedLink(id, asPath) }} />
            </div>
        </div>
    );
};

const OpcionsVideo = (props) => {
    const { hideInfo, changeAudioDesc, textSRAud, textAud, dataVideo } = props;
    const { titol, nomprograma, variants, subtitols } = dataVideo;

    const stylesOpcionsVideo = clsx({
        [styles.subtitolsAD]: true,
        [styles.hide]: hideInfo
    });

    return (
        <div className={styles.opcionsVideo}>
            {_getAudioDescripcio(variants) && (
                <div className='sr-only'>
                    <button className={styles.boto} onClick={changeAudioDesc}>
                        {textSRAud} audiodescripció de {nomprograma} {nomprograma && <>-</>} {titol}
                    </button>
                </div>
            )}

            <div className={stylesOpcionsVideo}>
                {_getAudioDescripcio(variants) && (
                    <div className={styles.botons}>
                        <button className={styles.boto} onClick={changeAudioDesc}>
                            <div className={styles.boto_text}>
                                {textAud} <span className='sr-only'>audiodescripció</span>
                            </div>
                            <Icona estil='clickable' alt='Icona audiodescripció' icona='audiodescripcio' />
                        </button>
                    </div>
                )}

                {!__isEmpty(subtitols) && (
                    <div className={styles.subtitols}>
                        <CodiEspectador codi='Subtitols' alt='icona subtítols' mida='20' />
                        <p className={styles.txt_subtitols}>{_getSubtitols(subtitols)}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const ItemVideoReel = forwardRef((props, publicRef) => {
    const { parametresExtraWs, versio, element, autoPlay, onNextVideo, setCurrentReel } = props;
    const dataVideo = getDataInfoVideo(element);
    const { id, endPubDate, imatge, state } = dataVideo;
    const [playVideo, setPlayVideo] = useState(autoPlay);
    const [hideInfo, setHideInfo] = useState(false);
    const [audioDesc, setAudioDesc] = useState(false);
    const [textAud, setTextAud] = useState('Activa');
    const [textSRAud, setTextSRAud] = useState('Vés a la versió amb');
    const [error, setError] = useState(false);
    const playerRef = useRef();

    useImperativeHandle(publicRef, () => ({
        playVideoReel() {
            setPlayVideo(true);
        },
        stopVideoReel() {
            setPlayVideo(false);
        }
    }));

    const stylesItem = clsx({
        [styles.itemvideo]: true,
        [styles.hide]: hideInfo
    });

    const ocultaInfo = (estat) => {
        setHideInfo(estat);
    };

    const changeAudioDesc = () => {
        setAudioDesc(!audioDesc);
        let textAud = audioDesc ? 'Activa' : 'Desactiva';
        let textSRAud = audioDesc ? 'Vés a la versió amb' : 'Vés a la versió sense';
        setTextAud(textAud);
        setTextSRAud(textSRAud);
    };

    const handleOnNextVideo = ({ currentVideo }) => {
        onNextVideo(id);
        setPlayVideo(false);
    };

    const isError = (error) => {
        setError(error);
    };

    const handleOnPlayVideo = (ev) => {
        setPlayVideo(true);
        setCurrentReel(id);
    };

    return (
        <div className={styles.itemModalReel} id={`reel-${id}`} ref={publicRef}>
            <article>
                <div className={stylesItem} id={`reel-video-${id}`}>
                    <div className={styles.video}>
                        {playVideo ? (
                            <Player
                                key='VoD'
                                ref={playerRef}
                                playerVideoTagId={`player-reel-${id}`}
                                id={id}
                                autoPlay={true}
                                versio={versio}
                                parametresExtraWs={parametresExtraWs}
                                className={styles.rmp}
                                ocultaInfo={ocultaInfo}
                                audioDesc={audioDesc}
                                textAud={textAud}
                                changeAudioDesc={changeAudioDesc}
                                setTextAud={setTextAud}
                                state={state}
                                endPubDate={endPubDate}
                                playerError={isError}
                                imatges={imatge}
                                onNextVideo={handleOnNextVideo}
                            ></Player>
                        ) : (
                            <PosterVideo dataVideo={dataVideo} onClick={handleOnPlayVideo} />
                        )}
                        {error && <p>Hem tingut un error al reproduir el vídeo</p>}
                        <OpcionsVideo
                            hideInfo={hideInfo}
                            changeAudioDesc={changeAudioDesc}
                            textSRAud={textSRAud}
                            textAud={textAud}
                            dataVideo={dataVideo}
                        />
                    </div>
                    <InfoVideo dataVideo={dataVideo} />
                </div>
            </article>
        </div>
    );
});

ItemVideoReel.defaultProps = {
    titol: '',
    programa: '',
    mostraH1: true,
    tipusContingut: null
};

ItemVideoReel.displayName = 'ItemVideoReel';
export default ItemVideoReel;
