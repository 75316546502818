import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Icona from 'components/3cat/ui/Icona/Icona';
import { useProducte } from 'hooks/3cat/useProducte';
import __isEmpty from 'lodash/isEmpty';
import styles from './boto.module.scss';

function Boto(props) {
    const {
        tag,
        text,
        tema,
        fons,
        estil,
        novaFinestra,
        rel,
        mida,
        link,
        linkTipus,
        linkId,
        linkSlug,
        slug2,
        slugPrograma,
        tipologia,
        tipologiaRelacionada,
        canals,
        type,
        format,
        icona,
        altIcona,
        iconaInline,
        isIconaDreta,
        id,
        onClick,
        name,
        className,
        dataTestId,
        data_testid,
        cleanUrlParams
    } = props;

    const dataTestAttribute = dataTestId ? dataTestId : data_testid; //ToDo a millorar a futur, per web utilitzem dataTestId i per apptv data_testid. Desde el core arribarà una cosa o una altra

    let { route } = props;
    const { isWebviewActive } = useProducte();

    const linkModificat =
        isWebviewActive && link === 'https://www.ccma.cat/tv3/sx3/' ? 'https://www.ccma.cat/3cat/tot-cataleg/sx3/' : link;

    const stylesBoto = clsx({
        [styles.boto]: true,
        [styles[tema]]: true,
        [styles[estil]]: true,
        [styles[fons]]: true,
        [styles[icona]]: true,
        [className]: !__isEmpty(className)
    });

    const _buttonContent = (text, icona, isIconaDreta) => {
        if (icona) {
            const iconPositionClass = isIconaDreta ? styles.dreta : '';
            const icon = (
                <Icona
                    className={`${styles.icona} ${iconPositionClass}`}
                    icona={icona}
                    inline={iconaInline}
                    alt={altIcona}
                    mida={mida}
                    ariaHidden={__isEmpty(altIcona)}
                />
            );
            return (
                <>
                    {!isIconaDreta && icon}
                    <span data-testid={dataTestAttribute} className={styles.label}>
                        {text}
                    </span>
                    {isIconaDreta && icon}
                </>
            );
        } else {
            return (
                <>
                    <span className={styles.label}>{text}</span>
                </>
            );
        }
    };

    const _buttonLink = () => {
        route = route
            ? route
            : {
                  href: linkModificat,
                  slug: linkSlug,
                  slugPrograma: slugPrograma,
                  slugRelacio: slug2,
                  id: linkId,
                  tipologia: tipologia,
                  tipologiaRelacionada: tipologiaRelacionada,
                  canals: canals,
                  type: type || linkTipus,
                  format: format
              };

        return (
            <Enllac
                route={route}
                className={stylesBoto}
                dataTestId={dataTestAttribute}
                novaFinestra={novaFinestra}
                rel={rel}
                cleanUrlParams={cleanUrlParams}
            >
                {_buttonContent(text, icona, isIconaDreta)}
            </Enllac>
        );
    };

    const _button = () => {
        return (
            <button className={stylesBoto} type={type} id={id} name={name} onClick={onClick} data-testid={dataTestAttribute}>
                {_buttonContent(text, icona, isIconaDreta)}
            </button>
        );
    };

    return tag === 'button' ? _button() : _buttonLink();
}

Boto.defaultProps = {
    tag: 'a',
    tema: 'T-3cat',
    fons: 'fosc',
    estil: 'ple',
    altIcona: '',
    mida: 16,
    onClick: undefined,
    iconaInline: false,
    isIconaDreta: false
};

export default Boto;
