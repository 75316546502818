import __includes from 'lodash/includes';
import __isFunction from 'lodash/isFunction';
import { useRouter } from 'next/router';
import { createContext, useEffect, useMemo, useState } from 'react';
import LoginDty from 'components/LoginDty/LoginDty';
import { isEditModeActive } from '@portal-internet/core';
import { dataResources } from 'utils/constants';

export const UserDelivertyContext = createContext();

const defaultUserData = {
    token: null,
    usuari: null
};

const prefixApiLocal = 'http://tomcatc7-test.local:8238';

const _getPrefixApi = () => {
    let prefixApi = dataResources.apiDELIVERTY;
    if (window.location.port) {
        prefixApi = prefixApiLocal;
    } else {
        const entorn = window?.jitConfig?.entorn;
        if (!!entorn && entorn !== 'aws_pro') {
            const prefixApiParts = prefixApi.split('.');
            prefixApiParts[0] = prefixApiParts[0] + '-test';
            prefixApi = prefixApiParts.join('.');
        }
    }
    return prefixApi;
};

const _parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    );
    JSON.parse(jsonPayload);
    return JSON.parse(jsonPayload);
};

const _getUserDataFromLocalStorage = () => {
    if (typeof window !== 'undefined') {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }
}

const _setUserDataToLocalStorage = (data) => {
    localStorage.setItem('token', JSON.stringify(data));
};

const _isUserLogged = () => {
    const userStorage = _getUserDataFromLocalStorage();
    return userStorage && userStorage?.token !== null;
}

const _updateIATToken = () => {
    const { token } = JSON.parse(localStorage.getItem('token'));
    const { iat } = _parseJwt(token);
    localStorage.setItem('iatToken', iat * 1000);
};

const UserDelivertyProvider = (props) => {
    const { children } = props;
    const router = useRouter();
    const [userData, setUserData] = useState({ ...defaultUserData });
    const [isEditModeEnable, setIsEditModeEnable] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [missatge, setMissatge] = useState();
    const REFRESH_TOKEN_TIME_MILISECONDS = 86400000; // 24H
    const isEditMode = isEditModeActive(router?.query);

    useEffect(() => {
        const removeUser = (error) => {
            localStorage.removeItem('token');
            localStorage.removeItem('iatToken');
            setIsEditModeEnable(false);
            setMissatge('ERROR REFRESCANT USUARI');
            setShowLogin(true);
        }

        const refreshToken = async () => {
            if (!localStorage.getItem('token')) return null;

            const prefixApi = _getPrefixApi();
            const { token } = JSON.parse(localStorage.getItem('token'));
            const pathApi = '/auth/refreshToken';
            const result = await fetch(`${prefixApi}${pathApi}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
            if (result && result.status === 401) {
                removeUser();
            }
            const data = await result?.json();
            if (result === undefined || result.status !== 200) {
                removeUser(result.error);
            } else {
                const newToken = JSON.parse(localStorage.getItem('token'));
                if (newToken) {
                    newToken.token = data.refreshToken;
                    _setUserDataToLocalStorage(newToken);
                    _updateIATToken();
                }
                setUserData(newToken);
                setIsEditModeEnable(true);
            }
            return null;
        }

        const isLogged = _isUserLogged();
        if (isEditMode) {
            if (isLogged) {
                refreshToken();
            } else {
                setShowLogin(true);
            }
        }
    }, [isEditMode]);

    const publicApi = useMemo(() => {
        const loginUser = async ({ user, clau }) => {
            if (user === undefined || clau === undefined) {
                setMissatge('Error, usuari o contrasenya incorrectes');
                return;
            }
            const prefixApi = _getPrefixApi();
            const pathApi = `/auth/login`;
            const dataLogin = { login: user, clau: clau };

            const response = await fetch(`${prefixApi}${pathApi}`, {
                method: 'POST',
                body: JSON.stringify(dataLogin),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            console.log(data);
            if (data?.token !== undefined && data?.usuari !== undefined) {
                _setUserDataToLocalStorage(data);
                setShowLogin(false);
                setUserData(data);
                setIsEditModeEnable(true);
            } else {
                setMissatge('Error, usuari o contrasenya incorrectes');
            }
        };

        return ({
            userData,
            loginUser,
            isEditModeEnable
        });
    }, [userData, isEditModeEnable]);

    return (
        <UserDelivertyContext.Provider value={publicApi}>
            {showLogin && <LoginDty missatge={missatge}></LoginDty>}
            {children}
        </UserDelivertyContext.Provider>
    );
};

export default UserDelivertyProvider;
