import React from 'react';
import NotFound from 'components/3cat/NotFound';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false }; // Define a state variable to track whether is an error or not
    }
    static getDerivedStateFromError(error) {
        return { hasError: true }; // Update state so the next render will show the fallback UI
    }
    componentDidCatch(error, errorInfo) {
        // No mostrar l'error a producció
        if (process.env.isDev && process.env.proxy) console.log({ error, errorInfo });
    }
    render() {
        // if the error is thrown you can render any custom fallback UI
        if (this.state.hasError) {
            const statusCode = 500;
            const errorMessage = 'Error intern';
            return <NotFound code={statusCode} text={errorMessage} cleanUrlParams={true} />;
        }
        return this.props.children; // Return children components in case of no error
    }
}

export default ErrorBoundary;
