import dynamic from 'next/dynamic';

const trescatinfoComponents = {
    AtencioAudiencia: { dynamicComponent: dynamic(() => import(`components/3catinfo/AtencioAudiencia`)) },
    Audiencies: { dynamicComponent: dynamic(() => import(`components/3catinfo/Audiencies`)) },
    AvuiEsNoticia: { dynamicComponent: dynamic(() => import(`components/3catinfo/AvuiEsNoticia`)) },
    BlocREDL: { dynamicComponent: dynamic(() => import(`components/3catinfo/BlocREDL`)) },
    Boto: { dynamicComponent: dynamic(() => import(`components/3catinfo/Boto`)) },
    BustiaDenuncia: { dynamicComponent: dynamic(() => import(`components/3catinfo/BustiaDenuncia`)) },
    CallToAction: { dynamicComponent: dynamic(() => import(`components/3catinfo/CallToAction`)) },
    CapcaleraSeccio: { dynamicComponent: dynamic(() => import(`components/3catinfo/CapcaleraSeccio`)) },
    CapMinutaminut: { dynamicComponent: dynamic(() => import(`components/3catinfo/CapMinutaminut`)) },
    Columna: { dynamicComponent: dynamic(() => import(`components/3catinfo/Columna`)) },
    Compartir: { dynamicComponent: dynamic(() => import(`components/3catinfo/Compartir`)) },
    ContinuaLlegint: { dynamicComponent: dynamic(() => import(`components/3catinfo/ContinuaLlegint`)) },
    DescarregaApp: { dynamicComponent: dynamic(() => import(`components/3catinfo/DescarregaApp`)) },
    Destacat: { dynamicComponent: dynamic(() => import(`components/3catinfo/Destacat`)) },
    Embed: { dynamicComponent: dynamic(() => import(`components/3catinfo/Embed`)) },
    Etiqueta: { dynamicComponent: dynamic(() => import(`components/3catinfo/Etiqueta`)) },
    Fila: { dynamicComponent: dynamic(() => import(`components/3catinfo/Fila`)) },
    Firmes: { dynamicComponent: dynamic(() => import(`components/3catinfo/Firmes`)) },
    Grafica: { dynamicComponent: dynamic(() => import(`components/3catinfo/Eleccions/Grafica`)) },
    ItemAutor: { dynamicComponent: dynamic(() => import(`components/3catinfo/ItemAutor`)) },
    ItemMinutaminut: { dynamicComponent: dynamic(() => import(`components/3catinfo/ItemMinutaminut`)) },
    ItemNoticia: { dynamicComponent: dynamic(() => import(`components/3catinfo/ItemNoticia`)) },
    LesMevesFirmes: { dynamicComponent: dynamic(() => import(`components/3catinfo/LesMevesFirmes`)) },
    Llistat: { dynamicComponent: dynamic(() => import(`components/3catinfo/Llistat`)) },
    LlistatFirmes: { dynamicComponent: dynamic(() => import(`components/3catinfo/LlistatFirmes`)) },
    LlistatNoticies: { dynamicComponent: dynamic(() => import(`components/3catinfo/LlistatNoticies`)) },
    LlistatCameres: { dynamicComponent: dynamic(() => import(`components/3catinfo/Temps/LlistatCameres`)) },
    Mapbox: { dynamicComponent: dynamic(() => import(`components/3catinfo/Mapbox`)) },
    MarcatgeEstructurat: { dynamicComponent: dynamic(() => import(`components/3catinfo/MarcatgeEstructurat`)) },
    Menu: { dynamicComponent: dynamic(() => import(`components/3catinfo/Menu`)) },
    MesLlegit: { dynamicComponent: dynamic(() => import(`components/3catinfo/MesLlegit`)) },
    Metadades: { dynamicComponent: dynamic(() => import(`components/3catinfo/Metadades`)) },
    MeuEntorn: { dynamicComponent: dynamic(() => import(`components/3catinfo/MeuEntorn`)) },
    Modal: { dynamicComponent: dynamic(() => import(`components/3catinfo/Modal`)) },
    MomentsClau: { dynamicComponent: dynamic(() => import(`components/3catinfo/ItemMinutaminut/MomentsClau`)) },
    Mosaic: { dynamicComponent: dynamic(() => import(`components/3catinfo/Mosaic`)) },
    Newsletter: { dynamicComponent: dynamic(() => import(`components/3catinfo/Newsletter`)) },
    NotFound: { dynamicComponent: dynamic(() => import(`components/3catinfo/NotFound`)) },
    Peu: { dynamicComponent: dynamic(() => import(`components/3catinfo/Peu`)) },
    Player: { dynamicComponent: dynamic(() => import(`components/3catinfo/Player`)) },
    Podcastaldia: { dynamicComponent: dynamic(() => import(`components/3catinfo/Podcastaldia`)) },
    Publicitat: { dynamicComponent: dynamic(() => import(`components/3catinfo/Publicitat`)) },
    Reels: { dynamicComponent: dynamic(() => import(`components/3catinfo/Reels`)) },
    Social: { dynamicComponent: dynamic(() => import(`components/3catinfo/Social`)) },
    Text: { dynamicComponent: dynamic(() => import(`components/3catinfo/Text`)) },
    Tab: { dynamicComponent: dynamic(() => import(`components/3catinfo/Tabs/Tab`)) },
    TabsCustom: { dynamicComponent: dynamic(() => import(`components/3catinfo/Tabs/TabsCustom`)) },
    TabList: { dynamicComponent: dynamic(() => import(`components/3catinfo/Tabs/TabList`)) },
    TabPanel: { dynamicComponent: dynamic(() => import(`components/3catinfo/Tabs/TabPanel`)) },
    TagsSeo: { dynamicComponent: dynamic(() => import(`components/3catinfo/TagsSeo`)) },
    TiraFirmes: { dynamicComponent: dynamic(() => import(`components/3catinfo/TiraFirmes`)) },
    Titol: { dynamicComponent: dynamic(() => import(`components/3catinfo/Titol`)) },
    UltimesNoticies: { dynamicComponent: dynamic(() => import(`components/3catinfo/UltimesNoticies`)) },
    ZonaEditable: { dynamicComponent: dynamic(() => import(`components/3catinfo/ZonaEditable`)) }
};

export { trescatinfoComponents };
