import { useEffect, useState } from 'react';
import { useLoading } from 'hooks/3catinfo/useLoading';
import SkeletonLoadingPages from './SkeletonLoadingPages/SkeletonLoadingPages';
import __isEmpty from 'lodash/isEmpty';
import styles from './loading.module.scss';

export default function Loading() {
    const { activeLoading, skeletonLoading } = useLoading();
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [interval, setInterval] = useState(null);
    const [skeletonPage, setSkeletonPage] = useState(false);
    const timeShowLoading = 800;
    const showSkeleton = true;

    useEffect(() => {
        if (activeLoading.active) {
            setInterval(null);
            setInterval(
                setTimeout(() => {
                    setLoadingVisible(activeLoading.active);
                }, timeShowLoading)
            );
        } else {
            clearTimeout(interval);
            setLoadingVisible(activeLoading.active);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLoading]);

    useEffect(() => {
        if (showSkeleton) {
            if (skeletonLoading && !__isEmpty(skeletonLoading)) {
                setSkeletonPage(<SkeletonLoadingPages {...skeletonLoading} />);
            } else {
                setSkeletonPage(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skeletonLoading]);

    useEffect(() => {
        if (skeletonPage) {
            document.documentElement.style.setProperty('scroll-behavior', 'auto', 'important');
            document.body.style.setProperty('scroll-behavior', 'auto', 'important');
            window.scrollTo({ top: 0, left: 0 });
            document.documentElement.style.removeProperty('scroll-behavior');
            document.body.style.removeProperty('scroll-behavior');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skeletonPage]);

    return (
        <>
            {skeletonPage}
            {loadingVisible && (
                <div className={styles.loadingContainer}>
                    <div className={styles.loadingBg}>
                        <div className={styles.loadingCenter}>
                            <svg className={styles.spinner} viewBox='0 0 200 200' fill='none'>
                                <defs>
                                    <linearGradient id='spinner-secondHalf'>
                                        <stop offset='0%' className={styles.color4} />
                                        <stop offset='70%' className={styles.color4} />
                                        <stop offset='100%' className={styles.color3} />
                                    </linearGradient>
                                    <linearGradient id='spinner-firstHalf'>
                                        <stop offset='0%' className={styles.color1} />
                                        <stop offset='50%' className={styles.color2} />
                                        <stop offset='100%' className={styles.color3} />
                                    </linearGradient>
                                </defs>

                                <g strokeWidth='8'>
                                    <path stroke='url(#spinner-secondHalf)' d='M 4 100 A 96 96 0 0 1 196 100' />
                                    <path stroke='url(#spinner-firstHalf)' d='M 196 100 A 96 96 0 0 1 4 100' />

                                    <path className={styles.cap} strokeLinecap='round' d='M 4 100 A 96 96 0 0 1 4 98' />
                                </g>

                                <animateTransform
                                    from='0 0 0'
                                    to='360 0 0'
                                    attributeName='transform'
                                    type='rotate'
                                    keyTimes='0; .25; .5; .75; 1'
                                    values='0 0 0;180 0 0;250 0 0;300 0 0;360 0 0'
                                    repeatCount='indefinite'
                                    dur='1300ms'
                                />
                            </svg>

                            <h3>{activeLoading.msg ? activeLoading.msg : 'Carregant'}</h3>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
