import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getUrlParameter } from '@portal-internet/core';
import { useModal } from 'hooks/3catinfo/useModal';
import { useSharedReel } from '../Reels/ModalReels/useSharedReels';
import Icona from '../ui/Icona/Icona';
import clsx from 'clsx';
import styles from './modal.module.scss';

const Modal = (props) => {
    const { openModal, modalContent, optionsModal, removeModal } = useModal();
    const [topContentScroll, setTopContentScroll] = useState(0);
    const { asPath } = useRouter();
    const { getSharedReelModal } = useSharedReel(props);

    const styleModal = clsx({
        [styles.modal]: true,
        [styles[optionsModal?.mode]]: !!optionsModal?.mode,
        [styles[optionsModal?.style]]: !!optionsModal?.style,
        [optionsModal.className]: !!optionsModal?.className
    });

    useEffect(() => {
        const idSharedVideoReel = getUrlParameter(asPath, 'shared_video');
        if (idSharedVideoReel) getSharedReelModal(idSharedVideoReel);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (optionsModal.mode === 'full-screen') setTopContentScroll(window.scrollY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalContent]);

    const onClickClose = () => {
        removeModal();
    };

    return openModal ? (
        <div className={styleModal} id='container-modal' style={{ top: topContentScroll }}>
            {optionsModal?.showControls && (
                <div className={styles.controls}>
                    <div className={styles.controlsInner}>
                        <button onClick={onClickClose}>
                            <Icona className={styles.iconaTancar} icona='tanca' alt='Icona tanca' mida='20' color='blanc' />
                        </button>
                    </div>
                </div>
            )}
            <div className={styles.inner} id='content-modal'>
                {modalContent}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default Modal;
