import { useEffect, useRef, useState } from 'react';
import { useModal } from 'hooks/3catinfo/useModal';
import ItemVideoReel from './ItemVideoReel';
import __isUndefined from 'lodash/isUndefined';
import styles from '../reels.module.scss';

const _getNextReel = (currentId) => {
    const currentDiv = document.getElementById(`reel-${currentId}`);
    const nextElementSibling = currentDiv.nextElementSibling?.getAttribute('id').replace('reel-', '');

    if (__isUndefined(nextElementSibling))
        return currentDiv.parentNode.firstElementChild?.getAttribute('id').replace('reel-', '');

    return nextElementSibling;
};

const ModalReels = (props) => {
    const { items, idSelected } = props;
    const [selectedReel, setSelectedReel] = useState(idSelected);
    const reelsListRef = useRef({});
    const { centerDivOnModal } = useModal();

    useEffect(() => {
        centerDivOnModal(`reel-video-${selectedReel}`);
        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNextVideo = (currentId) => {
        const nextReelId = _getNextReel(currentId);
        reelsListRef.current[nextReelId].playVideoReel();
        setSelectedReel(nextReelId);
        centerDivOnModal(`reel-video-${nextReelId}`);
    };

    const setCurrentReel = (nextReelId) => {
        if (nextReelId !== selectedReel) {
            reelsListRef.current[selectedReel].stopVideoReel();
            setSelectedReel(nextReelId);
            centerDivOnModal(`reel-video-${nextReelId}`);
        }
    };

    const _iterateItems = () => {
        return items.map((item) => (
            <ItemVideoReel
                key={`item-modal-reel-${item.id}`}
                ref={(element) => (reelsListRef.current[item.id] = element)}
                autoPlay={item.id === idSelected}
                parametresExtraWs=''
                versio='vast'
                element={item}
                onNextVideo={onNextVideo}
                setCurrentReel={setCurrentReel}
            />
        ));
    };

    return <div className={styles.contentModalReels}>{_iterateItems()}</div>;
};

ModalReels.defaultProps = {
    items: {}
};

export default ModalReels;
