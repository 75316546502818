import { getUrlFriendly } from '@portal-internet/core';
import clsx from 'clsx';
import Boto from 'components/3catinfo/Boto';
import Enllac from 'components/3catinfo/Enllac';
import __isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { useState } from 'react';
import Icona from '../ui/Icona';
import Modal from './Modal';
import styles from './compartir.module.scss';
import { useViewport } from 'hooks/useViewport';

export default function Compartir(props) {
    const {
        className,
        mida,
        estil,
        titol,
        mostraTwitter,
        mostraWhatsapp,
        mostraFacebook,
        mostraEnllac,
        route,
        obreEsquerra,
        titolCompartirMobil
    } = props;
    const { isMobileDevice } = useViewport();
    const [showModal, setShowModal] = useState(false);

    const toggleModalHandler = async (url) => {
        if (navigator.share && isMobileDevice) {
            try {
                await navigator.share({
                    title: titolCompartirMobil,
                    text: titolCompartirMobil,
                    url
                });
            } catch (error) {
                console.error('Error al compartir:', error);
            }
        } else {
            setShowModal(!showModal);
        }
    };

    const [textCopia, setTextCopia] = useState("Copia l'enllaç");

    const stylesCompartir = clsx({
        [styles.compartir]: true,
        [styles[estil]]: true,
        [styles.esquerra]: obreEsquerra,
        [className]: !__isEmpty(className)
    });

    const router = useRouter();

    const _canviaText = () => {
        setTextCopia('Copiat!');
        setTimeout(() => {
            setTextCopia("Copia l'enllaç");
        }, 1000);
    };

    const _getCanal = (canals) => {
        const c = canals.canal ? canals.canal : canals;
        const canalMain = c.find((canal) => canal.main === true);
        return canalMain?.desc || c?.[0]?.desc || 'sense';
    };

    const _getUrl = (route) => {
        if (route.href) return route.href;
        const canalMain = route?.canals ? _getCanal(route.canals) : 'sense';
        return getUrlFriendly({ ...route, routePath: route?.type, canal: canalMain, producte: 'web' });
    };

    const slug = !__isEmpty(route) ? route.slug : titol;

    const url = __isEmpty(route) ? 'https://www.ccma.cat' + router.asPath : 'https://www.ccma.cat' + _getUrl(route);

    const hitAudiencies = (type) => {
        const xarxa = type || 'XS desconeguda';
        _satellite?.track('scCompartir', { xarxaAccioContingut: xarxa + slug });
    };

    return (
        <div className={stylesCompartir}>
            <button className={styles.boto} onClick={() => toggleModalHandler(url)} aria-label='Comparteix'>
                <Icona
                    inline={true}
                    mida={mida}
                    className={styles.icona}
                    isBlanc={estil === 'negatiu'}
                    icona={showModal ? 'tanca' : 'compartir'}
                    alt='Compartir'
                />
            </button>

            <Modal isOpen={showModal}>
                <div className={styles.overlay}>
                    {mostraEnllac && (
                        <Boto
                            tag='button'
                            text={textCopia}
                            estil='transparent'
                            icona='enllac'
                            className={styles.btn_copia}
                            onClick={() => {
                                navigator.clipboard.writeText(url);
                                _canviaText();
                            }}
                        />
                    )}
                    {mostraWhatsapp && (
                        <Enllac
                            href={'https://wa.me/?text=' + url + '?ext=SM_WP_F4_CE24_'}
                            onClick={() => {
                                hitAudiencies('Whatsapp:share:');
                            }}
                            className={styles.link}
                            data-action='share/whatsapp/share'
                            target='_blank'
                        >
                            <Icona icona='whatsappGran' inline={true} className={styles.iconaSocial} alt='Icona Whatsapp' />
                            <span className='sr-only'>Whatsapp</span>
                        </Enllac>
                    )}
                    {mostraFacebook && (
                        <button
                            onClick={() => {
                                const cadena = 'https://www.facebook.com/sharer/sharer.php?u=' + url + '?ext=SM_FB_F4_CE24_';
                                window.open(cadena, 'Facebook', 'toolbar=0, status=0, width=550, height=500');
                                hitAudiencies('Facebook:share:');
                            }}
                            className={styles.link}
                        >
                            <Icona icona='facebookGran' inline={true} className={styles.iconaSocial} alt='Icona Facebook' />
                            <span className='sr-only'>Facebook</span>
                        </button>
                    )}
                    {mostraTwitter && (
                        <button
                            onClick={() => {
                                const cadena =
                                    'https://twitter.com/intent/tweet?text=' + titol + '&url=' + url + '?ext=SM_TW_F4_CE24_';
                                window.open(cadena, 'Twitter', 'toolbar=0, status=0, width=550, height=500');
                                hitAudiencies('Twitter:share:');
                            }}
                            href={'https://twitter.com/intent/tweet?text=' + titol + '&url=' + url}
                            className={styles.link}
                        >
                            <Icona icona='x' inline={true} className={styles.iconaSocial} alt='Icona X' />
                            <span className='sr-only'>X</span>
                        </button>
                    )}
                </div>
            </Modal>
        </div>
    );
}

Compartir.defaultProps = {
    mida: '20',
    titol: '',
    mostraTwitter: true,
    mostraWhatsapp: true,
    mostraFacebook: true,
    mostraEnllac: true,
    obreEsquerra: false,
    titolCompartirMobil: ''
};
