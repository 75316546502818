import { dataResources } from '@portal-internet/core';

const BASE_PATH = '';
dataResources.basePath = BASE_PATH;

const WEBPROGRAMA_HOME_PREFIX_LAYOUT_CUSTOM = '_custom';
dataResources['homeWPCustom'] = WEBPROGRAMA_HOME_PREFIX_LAYOUT_CUSTOM;
const WEBPROGRAMA_HOME_PREFIX_TEMPLATE = '_homewp_';
dataResources['homeWPTemplate'] = WEBPROGRAMA_HOME_PREFIX_TEMPLATE;

dataResources.logosPath = 'https://statics.3cat.cat/recursos/3cat/logos/web/';
dataResources.urlFacebook = 'https://www.facebook.com/324cat';
dataResources.urlTwitter = 'https://www.x.com/324cat';
dataResources.urlInstagram = 'https://www.instagram.com/324cat/';
dataResources.urlRSS = 'https://api.3cat.cat/noticies?_format=rss&origen=frontal&frontal=n324-portada-noticia&version=2.0';

export { dataResources };
