import { Imatge } from '@portal-internet/components';
import { getStorageValue, setStorageValue } from '@portal-internet/core';
import Enllac from 'components/3catinfo/Enllac/Enllac';
import Boto from 'components/3catinfo/Boto/Boto';
import Icona from 'components/3catinfo/ui/Icona/Icona';
import { useUser } from 'hooks/useUser';
import __isUndefined from 'lodash/isUndefined';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { dataResources } from 'utils/constants';
import styles from './onboarding.module.scss';
import globalStyles from '../../../../web/styles/3cat/globals.module.scss';

const { basePath } = dataResources;

export default function Component(props) {
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [showedToday, setShowedToday] = useState(true);

    const router = useRouter();
    const refOnboarding = useRef();
    const isTest = typeof window !== 'undefined' && window.isCypressTest;
    const { isAuthenticated, signUp, signIn } = useUser();
    const nomCookie = 'onboarding';
    const estructuraCookieDefault = {
        day: '2020/01/01'
    };

    const isEmbedOrPopup = router.asPath.includes('/popup/') || router.asPath.includes('/embed/');

    const saveOnStorage = (values) => {
        setStorageValue(nomCookie, values, {
            path: '/',
            domain: '.ccma.cat',
            expires: Infinity
        });
    };
    const initializeValues = (data) => {
        let response;
        if (__isUndefined(data)) {
            //first Time
            response = estructuraCookieDefault;
        } else {
            response = data;
        }
        return response;
    };

    const fetchDayCookieData = async () => {
        const data = await getStorageValue(nomCookie);
        let value = initializeValues(data);
        let todayDate = getToday();
        if (value.day !== todayDate) {
            value.day = todayDate;
            setShowedToday(false);
            saveOnStorage(value);
        }
    };

    const tancaOnboarding = () => {
        netejeUrlOnboarding();
        setShowOnboarding(false);
        setShowedToday(true);
        document.getElementById('__mainContent').removeAttribute('inert');
        //document.getElementById('__mainContent').focus();
    };

    const netejeUrlOnboarding = () => {
        let url = router.asPath?.split('#')[0];
        router.push(url);
    };

    const getToday = () => {
        const dateObj = new Date();
        const month = dateObj.getUTCMonth() + 1; //months from 1-12
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        return year + '/' + month + '/' + day;
    };

    useEffect(() => {
        fetchDayCookieData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isAuthenticated && router.asPath.includes('onboarding')) {
            router.push({
                hash: ''
            });
        }
    }, [router, isAuthenticated]);

    useEffect(() => {
        if (!isAuthenticated && !isTest && showOnboarding && !isEmbedOrPopup && !showedToday) {
            if (!router.asPath.includes('onboarding')) {
                router.push({
                    hash: 'onboarding=true'
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOnboarding, showedToday, isAuthenticated]);

    useEffect(() => {
        if (!__isUndefined(refOnboarding.current) && showOnboarding === true && !isTest && !showedToday) {
            refOnboarding.current.focus();
            document.getElementById('__mainContent').setAttribute('inert', true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refOnboarding.current]);

    useEffect(() => {
        if (!isAuthenticated && isAuthenticated !== null) {
            setShowOnboarding(true);
        }
    }, [isAuthenticated]);

    const hash = router.asPath?.split('#')[1];

    if ((showOnboarding && !isTest && !showedToday && !isEmbedOrPopup) || hash?.includes('onboarding=true')) {
        return (
            <div className={styles.mask + ' ' + globalStyles.trescat}>
                <div className={styles.onBoarding} aria-modal='true'>
                    <button className={styles.botoTanca} onClick={() => tancaOnboarding()} ref={refOnboarding}>
                        <Icona
                            isBlanc={true}
                            icona='tanca'
                            alt='Tanca popup "Crea un compte i personalitza la teva experiència"'
                        />
                    </button>
                    <div className={styles.content}>
                        <div className={styles.logo}>
                            <Imatge
                                src={basePath + '/_resources/logos/3cat.svg?v=1.2408.3'}
                                width='125'
                                height='36'
                                alt='3cat, logotip 3cat'
                            />
                        </div>
                        <h2 className={styles.titol}>
                            La plataforma de continguts en <span className={styles.dotted}>català</span> i de{' '}
                            <span className={styles.dotted}>qualitat</span>
                        </h2>
                        <p className={styles.titoLlista}>Crea un compte i personalitza la teva experiència:</p>
                        <ul className={styles.llistaPunts}>
                            <li>
                                <Icona isBlanc={true} className={styles.ico_check} icona='check' alt='' />
                                Segueix els programes i les sèries on els has deixat
                            </li>
                            <li>
                                <Icona isBlanc={true} className={styles.ico_check} icona='check' alt='' />
                                Crea la teva llista de favorits
                            </li>
                            <li>
                                <Icona isBlanc={true} className={styles.ico_check} icona='check' alt='' />
                                Rep recomanacions personalitzades
                            </li>
                        </ul>
                        <Boto
                            onClick={() => signIn()}
                            tag='button'
                            className={styles.boto}
                            estil='T-onboarding'
                            text='Crea el teu compte gratuït'
                        />
                        <p className={styles.iniciaSessio}>
                            <Boto
                                tag='button'
                                text='Inicia sessió'
                                estil='enllac'
                                onClick={() => signUp()}
                                dataTestId='onboarding-inicia-sessio-button'
                            />{' '}
                            si ja tens compte​
                        </p>
                        <span className={styles.ajuda}>
                            <Enllac href='https://www.3cat.cat/ajuda'>3cat.cat/ajuda</Enllac>
                        </span>
                    </div>
                    <div className={styles.fotos}>
                        <div className={styles.foto1}>
                            <div className={styles.foto}>
                                <div className={styles.fotoWrapper}>
                                    <Imatge
                                        src={'/_resources/imatges/onboarding/foto1.jpg?v=1.2408.3'}
                                        layout='fill'
                                        objectFit='cover'
                                        alt=''
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.foto2}>
                            <div className={styles.foto}>
                                <div className={styles.fotoWrapper}>
                                    <Imatge
                                        src={'/_resources/imatges/onboarding/foto2.jpg?v=1.2408.3'}
                                        layout='fill'
                                        objectFit='cover'
                                        alt=''
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.foto3}>
                            <div className={styles.foto}>
                                <div className={styles.fotoWrapper}>
                                    <Imatge
                                        src={'/_resources/imatges/onboarding/foto3.jpg?v=1.2408.3'}
                                        layout='fill'
                                        objectFit='cover'
                                        alt=''
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.foto4}>
                            <div className={styles.foto}>
                                <div className={styles.fotoWrapper}>
                                    <Imatge
                                        src={'/_resources/imatges/onboarding/foto4.jpg?v=1.2408.3'}
                                        layout='fill'
                                        objectFit='cover'
                                        alt=''
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return false;
    }
}
