// ----------- estils jwplayer del antic portal -----------
// TODO?? faltaria portar https://statics.3cat.cat/skinplayer/jwplayer8/ccma/ccma.css del portal
// import 'styles/modul-rmp.css';
// import 'styles/ccma.css';
// --------------------------------------------
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { getDynamicComponents } from 'components';
import AvisCookies from 'components/3cat/AvisCookies';
import Modal from 'components/Modal/Modal';
import ErrorBoundary from 'components/3cat/ErrorBoundary';
import Loading from 'components/3cat/Loading';
import Onboarding from 'components/3cat/Onboarding';
import ServicesListener from 'components/ServicesListener';
import ContentUserProvider from 'context/3cat/ContentUserProvider';
import GlobalVarsProvider from 'context/GlobalVarsProvider';
import HistoryProvider from 'context/3cat/HistoryProvider';
import LoadingProvider from 'context/3cat/LoadingProvider';
import ProducteProvider from 'context/3cat/ProducteProvider';
import PublicitatProvider from 'context/PublicitatProvider';
import RedlMetadataProvider from 'context/RedlMetadataProvider';
import ServicesProvider from 'context/ServicesProvider';
import UserDelivertyProvider from 'context/UserDelivertyProvider';
import UserProvider from 'context/UserProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { msalConfig } from 'utils/login/authConfig';
import styles from '../../../web/styles/3cat/globals.module.scss';
//NO ELIMINAR lodash.legacy-interop EL NECESSITA TVCPLAYERCORE

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();
const DynamicComponents = getDynamicComponents('3cat');

const App3cat = ({ Component, pageProps }) => {
    return (
        <ProducteProvider>
            <ErrorBoundary>
                <GlobalVarsProvider>
                    <QueryClientProvider client={queryClient}>
                        <HistoryProvider>
                            <MsalProvider instance={msalInstance}>
                                <LoadingProvider>
                                    <RedlMetadataProvider>
                                        <ServicesProvider>
                                            <UserProvider>
                                                <UserDelivertyProvider>
                                                    <ContentUserProvider>
                                                        <PublicitatProvider>
                                                            <AvisCookies {...pageProps} />
                                                            <div id='__mainContent' className={styles.trescat}>
                                                                <ServicesListener {...pageProps} />
                                                                <Component {...pageProps} dynamicComponents={DynamicComponents} />
                                                                <Loading />
                                                            </div>
                                                            <div id='app-modal' className={styles.trescat} />
                                                            <Modal />
                                                            <Onboarding />
                                                            {process.env.isDev && <ReactQueryDevtools initialIsOpen={false} />}
                                                        </PublicitatProvider>
                                                    </ContentUserProvider>
                                                </UserDelivertyProvider>
                                            </UserProvider>
                                        </ServicesProvider>
                                    </RedlMetadataProvider>
                                </LoadingProvider>
                            </MsalProvider>
                        </HistoryProvider>
                    </QueryClientProvider>
                </GlobalVarsProvider>
            </ErrorBoundary>
        </ProducteProvider>
    );
};

export default App3cat;
